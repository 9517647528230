import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import axios from "axios";
import { navigate } from "gatsby";
import { Form, Formik, Field } from "formik";
import Layout from "../Components/Layout";
import Box from "../Components/Box2";
import Input from "../Components/Input";
import Checkbox from "../Components/Checkbox";
import { RaisedButton } from "../Components/Buttons";
import 'react-datepicker/dist/react-datepicker.css';
import Owl from "../assets/images/peleda 1.png";
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';


function getValidationSchema(value2) {
  const baseSchema = {
    name: Yup.string().required("Laukas yra privalomas"),
    surname: Yup.string().required("Laukas yra privalomas"),
    email: Yup.string()
      .email("Neteisingas el.pašto formatas")
      .required("Laukas yra privalomas"),
    //schoolSel: Yup.string().required("Laukas yra privalomas"),
    phone: Yup.string().required("Laukas yra privalomas"),
    city: Yup.string().required("Laukas yra privalomas"),
    age: Yup.string().required("Laukas yra privalomas"),
   // rules: Yup.bool(true).oneOf([true]),
    privacy: Yup.bool(true).oneOf([true]),
  };

  if (value2 === 2) {
    baseSchema.schoolSel = Yup.string().required("Laukas yra privalomas");
    baseSchema.class = Yup.string().required("Laukas yra privalomas");
  }
  else {
    baseSchema.schoolSel = Yup.string().notRequired();
    baseSchema.class = Yup.string().notRequired();
  }

  return Yup.object().shape(baseSchema);
}

export default () => {

  const [data, setData] = useState([]);
  const [inputValueMok, setInputValueMok] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [SubMsg, setSubMsg] = useState(0)

  const [serverResponse, setServerResponse] = useState(null)

  const [value2, setValue2] = useState("1");

  const optionClass = [
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' }
  ]

  //let SubMsg = 0;

  const onSubmit = values => {

    axios
      .post(
        "https://dzk.lrt.lt/egzaminas/api/register2",
        new URLSearchParams({
          ...values,
          rules: values.rules ? 1 : 0,
          newsletter: values.newsletter ? 1 : 0,
          type: value2,
          schoolSel: values.schoolSel != null ? values.schoolSel.value : "",
          class: values.class != null ? values.class.value : "",
        }).toString(),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then(response => {
        if (response.data.status === "error") {
          setServerResponse(response.data.message)
        } else {
          navigate("/lauksime")
          //setSubMsg(1);
        }
      })
  }

  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    //if (inputValueMok) {
      fetch(`https://dzk.lrt.lt/egzaminas/api/search2`)
        .then(response => response.json())
        .then(result => {
          console.log(result);
          const formattedData = result.map(item => ({
            value: item.id,
            label: item.name
          }));
          setData(formattedData);
        })
        .catch(error => console.log('Error fetching data:', error));
    //}
  }, [inputValueMok]);

  const handleChange = (value) => {
    setSelectedValue(value);


  };

  const handleInputChange = (inputValueMok) => {
    setInputValueMok(inputValueMok);
  }

  return (
    <Layout>
      {(SubMsg === 0) && (
      <div className="registration-page">
        <Formik
          
          validationSchema={getValidationSchema(value2)}
          initialValues={{
            name: "",
            surname: "",
            email: "",
            phone: "",
            schoolSel: "",
            city: "",
            age: "",
            class: "",
            rules: false,
            newsletter: false,
            selectedItem: '',
            organisation: ""
          }}
          onSubmit={onSubmit}
          component={( {setFieldValue} ) => (
            <Form>
              {/*<FormObserver  //onSubmit={(e) => e.preventDefault()} />*/}
              <Box>

                <h2 id="regTitle">REGISTRACIJA</h2>

                <Field type="hidden" name="type" value="0"/>


                <div className="registration-page--form-row">

                  <div className="box">

                  <input type="radio" name="select" aria-label="Mokinys" id="option-1" value="1" checked={value2 === "1"} onChange={(e) => setValue2(e.currentTarget.value)}/>
                  <input type="radio" name="select" aria-label="Suaugęs asmuo" id="option-2" value="2" checked={value2 === "2"} onChange={(e) => setValue2(e.currentTarget.value)}/>
                  

                          <label htmlFor="option-1" className="option-1">
                            <div className="dot"></div>
                            <div className="text">
                              <h4>Mokinys</h4>
                            </div>
                          </label>

                          <label htmlFor="option-2" className="option-2">
                            <div className="dot"></div>
                            <div className="text">
                              <h4>Suaugęs asmuo</h4>
                            </div>
                          </label>
                          
                  </div>
                </div>



                {(value2 !== 'no') && (
                <div>

                <div className="registration-page--form-row">
                  <Field
                    component={Input}
                    type="text"
                    placeholder=""
                    required
                    name="name"
                    label="Vardas"
                  />
                </div>

                <div className="registration-page--form-row">
                  <Field
                    component={Input}
                    type="text"
                    placeholder=""
                    required
                    name="surname"
                    label="Pavardė"
                  />
                </div>

                <div className="registration-page--form-row">
                  <Field
                    component={Input}
                    type="text"
                    placeholder=""
                    required
                    name="email"
                    label="El. paštas"
                  />
                </div>

                <div className="registration-page--form-row">
                  <Field
                    component={Input}
                    type="text"
                    placeholder=""
                    required
                    name="phone"
                    label="Telefono numeris"
                  />
                </div>

                  {(value2 === "1") && (

                  <div className="registration-page--form-row">
                    <div id="dropTitle">
                    <label className="labelReg">Mokykla<sup style={{fontSize: "calc(26px*1.31)", top:"0"}}>*</sup></label>
                    <Field name="schoolSel">
                      {({ field, meta, form: { setFieldValue } }) => {
                        return (

                    <CreatableSelect
                      options={data}
                      //onInputChange={handleInputChange}
                      //onInputChange={(value) => setInputValueMok(value)}
                      //isSearchable={true}
                    // onChange={handleChange}
                      value={selectedValue}

                      onChange={val => {
                        setFieldValue("schoolSel", val);
                        setSelectedValue(val);
                        console.warn(val.value);
                      }}

                      //onChange={handleChange}
                      className="selectDzk"
                      classNamePrefix="selectDzksel"
                      placeholder={<div id="PlaceholderCol">Pasirinkite arba įveskite...</div>}
                      isClearable
                      formatCreateLabel={(inputValue) => `Įvesti pavadinimą: "${inputValue}"`}
                    />
                        );
                      }}
                    </Field>
                      </div>
                  </div>
                    )}

                   <div className="registration-page--form-row">
                  <Field
                    component={Input}
                    type="text"
                    placeholder=""
                    required
                    name="city"
                    label="Miestas"
                  />
                </div>

                <div className="registration-page--form-row">
                  <Field
                    component={Input}
                    type="text"
                    placeholder=""
                    required
                    name="age"
                    label="Amžius"
                  />
                </div>
                
                {(value2 === "1") && (
                <div className="registration-page--form-row">
                  <div id="dropTitle">
                    <label className="labelReg">Klasė, kurioje mokaisi<sup style={{fontSize: "calc(26px*1.31)", top:"0"}}>*</sup></label>
                
                  <Field name="class" label="labas">
                    {({ field, meta, form: { setFieldValue } }) => {
                      return (
                  <Select
                    {...field}
                    placeholder={<div id="PlaceholderCol">Pasirinkite...</div>}
                    options={optionClass}
                    className="selectDzk"
                    classNamePrefix="selectDzksel"
                    onChange={val => {
                      setFieldValue("class", val);
                      console.warn(val.value);
                    }}
                  />
                      );
                    }}
                  </Field>
                </div>
              </div>
                )}


              {(value2 === "2") && (
                <div className="registration-page--form-row">
                <Field
                  component={Input}
                  type="text"
                  placeholder=""
                  name="organisation"
                  label="Organizacija"
                />
              </div>
              )}

                <div className="registration-page--form-row">
                  <Field
                    component={Input}
                    type="text"
                    placeholder=""
                    name="known"
                    label="Kur sužinojai apie konkursą"
                  />
                </div>
                  <br /><br /><br id="tarpas1"/><br id="tarpas1"/>

                <div className="registration-page--form-row">
                  <Field 
                    component={Checkbox} 
                    name="privacy">
                    <div>
                    Susipažinau ir sutinku su projekto ir <a href="/privatumas" target="_blank" rel="noreferrer">privatumo taisyklėmis*</a>
                    </div>
                  </Field>
                </div>

                <div className="registration-page--form-row">
                  <Field 
                    component={Checkbox} 
                    name="newsletter">
                    <div>
                    Noriu gauti informacinius pranešimus, vertinimą <br/>
                    ir informaciją apie laimėtojus elektroniniu paštu
                    </div>
                  </Field>
                </div>

                </div>)}

    
                {serverResponse && (
                  <div className="registration-page--server-message">
                    {serverResponse}
                  </div>
                )}
              

              <RaisedButton variant="square" type="submit">
                REGISTRUOKIS
              </RaisedButton>
              <img src={Owl} />

            </Box>
            </Form>
          )}
        />
      </div>
      )}
      {(SubMsg !== 0) && (
        <div className="registration-page">
          <div className="registration-success">
            <h3 style={{color: "#5dc5cc"}}>SVEIKINAME,</h3>
            <h4>JŪS SĖKMINGAI UŽSIREGISTRAVOTE Į <br/>
            DEMOKRATIJOS ŽINIŲ KONKURSĄ.<br/>
            PATVIRTINKITE SAVO PASKYRĄ,<br/>
            SPUSTELDAMI NUORODĄ EL. LAIŠKE, KURĮ JUMS IŠSIUNTĖME.<br/>
            BALANDŽIO 16-17 D. 7-23.59 VAL. KONKURSO KLAUSIMUS GALĖSITE<br/>
            SPRĘSTI PRISIJUNGĘS SU SAVO<br/>
            EL. PAŠTU.</h4>
          </div>
        </div>
      )}
    </Layout>
  )
}
